<template>
  <div class="main-content">
    <div class="main-title">顶部图片</div>
    <div class="main-btn">
      <span class="btn-add" @click="add">添加</span>
    </div>
    <template v-for="(item,key) in advList">
    <div class="from" :key="item.id">
      <div class="banner-box">
        <label :for="'def_input_'+item.id">
          <img :src="item.imgurl || require('@/assets/upload_black_icon.png')" alt=" ">
        </label>
        <input type="file" accept="image/*" hidden :id="'def_input_'+item.id" @change="setBanner">
      </div>

      <div class="row">
        <div class="title">链接地址</div>
        <img class="del" @click="del(key)" src="@/assets/user_works_del_icon.png">
        <input type="text" placeholder="请输入链接地址" v-model.trim="item.link_url">
      </div>
    </div>
    </template>
    <div class="from">
      <div class="btn-box">
        <span class="btn-confirm" @click="edit">保存</span>
      </div>
    </div>
  </div>
</template>

<script>
import {getAdvList,editAdvBatch} from "@/request/api";
import {Warning} from "@/plugins/warning";
import { LoadingFn } from '@/plugins/loading';
import $ from "jquery";
import OSS from "ali-oss";

export default {
  name: "page_about_banner",
  components:{

  },
  data() {
    return {
      index:-1,
      advList:{},
    }
  },
  computed: {

  },
  created() {
    this.getInfo();
  },
  mounted() {

  },
  methods: {
    add(){
      console.log(this.advList.length)
      if(this.advList.length >= 5){
        Warning.open("最多只能添加5张图片")
      }else{
        this.advList.splice(0,0,{"id":this.index,"imgurl":null,"link_url":""})
        this.index--;
      }
    },
    edit(){
      let lists = [];
      this.advList.forEach(function(item) {
        let id = item.id;
        if(id <= 0){
          id = 0
        }
        const data = {
          "id":id,
          "postion_id":6,
          "title":"about_match_"+id,
          "imgurl":item.imgurl,
          "link_url":item.link_url
        };

        lists.splice(0,0,data);
      });
      const requestData = {
        "adv":JSON.stringify(lists),
      };
      console.log(requestData)
      editAdvBatch(requestData).then(()=>{
        this.getInfo();
        Warning.success("操作成功");
      })
    },
    del(key){
      this.advList.splice(key,1)
    },
    getInfo(){
      /*
      const requestData = {"id":12};
      getAdvInfo(requestData).then((res)=>{
        this.advObj = res.data;
      })
      */
      const requestData = {"postion_id":6};
      getAdvList(requestData).then((res)=>{
        this.advList = res.data.list;
      })
    },
    setBanner(e){
      LoadingFn.open();
      const that = this;
      const file = e.target.value;
      let imageId = e.target.id.replace("def_input_","");
      let filename=file.replace(/.*(\/|\\)/, "");
      let fileExt=(/[.]/.exec(filename)) ? /[^.]+$/.exec(filename.toLowerCase()) : '';
      let storage = JSON.parse(window.localStorage.getItem('STORAGE'));
      $.ajax({
        url: process.env.VUE_APP_SERVER_URL+'/file/upload',
        data:{
          suffix:fileExt[0],
          path:"postion"
        },
        headers:{
          Authorization:storage.infoModule.token
        },
        async: true,
        global: false,
        type: 'POST',
        dataType: 'json',
        timeout:1000*7,
        success: function(data) {
          if (data.status === 200) {
            const client = new OSS({
              region: data.result.data.region,
              accessKeyId: data.result.data.access_key_id,
              accessKeySecret: data.result.data.access_key_secret,
              stsToken: data.result.data.security_token,
              bucket: data.result.data.bucket,
            });

            client.multipartUpload(data.result.data.key, e.target.files[0], {
              // 设置上传回调。
              progress:(p) => {
                if(p>=1){
                  //that.thumb = data.result.data.url
                  //that.advObj.imgurl = data.result.data.full_url
                  that.advList.forEach(function(item) {
                    if(item.id == imageId){
                      item.imgurl = data.result.data.full_url;
                    }
                  });

                  LoadingFn.close();
                }
              }
            });
          }else {
            Warning.open(data.msg);
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content{
  width: 100%;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-bottom: 45px;
  .main-title{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-left: 30px;
    margin-top: 30px;
  }
  .main-btn{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-right: 30px;
    margin-top: 30px;
    .btn-add{
      display: block;
      width: 100px;
      line-height: 48px;
      font-size: 21px;
      font-weight: 300;
      text-align: center;
      cursor: pointer;
      color: #fff;
      border-radius: 25px;
      background-color: #ba0704;
    }
  }
  .from{
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    border-collapse:separate;
    border-spacing:0px 30px;
    position: relative;
    .row{
      width: 100%;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      .title{
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #000000;
        margin-bottom: 15px;
      }
      & > input{
        height: 50px;
        width: 100%;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 2px solid #DDD1B4;
        padding-left: 15px;
        padding-right: 15px;
      }
      & > textarea{
        height: 200px;
        min-width: 500px;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 2px solid #DDD1B4;
        padding: 15px;
      }
      .el-select{
        margin-right: 15px;
      }
      .address{
        margin-top: 15px;
        min-width: 600px;
      }
      .del{
        width: 18px;
        height: 23px;
        position: absolute;
        top: 25px;
        right: 35px;
        cursor: pointer;
      }
    }
    .btn-box{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
      .btn-confirm{
        display: block;
        width: 100%;
        line-height: 48px;
        font-size: 21px;
        font-weight: 300;
        text-align: center;
        cursor: pointer;
        color: #fff;
        border-radius: 25px;
        background-color: #ba0704;
      }
    }
    .banner-box{
      width: 100%;
      height: 300px;
      margin-top: 25px;
      label {
        display: block;
        width: 100%;
        height: 100%;
        & > img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
          cursor: pointer;
          margin-top: 15px;
          border: 0.5px solid $blockColor--1;
        }
      }
    }
  }
}
</style>
