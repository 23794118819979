<template>
  <div class="main-content-box">
    <bannerEl/>
    <configZone1EL/>
    <configZone2EL/>
    <bannerZone2EL/>
    <matchOrgEl/>
    <memberOrgEl/>
    <expertCommitteeEL/>
    <executiveCommitteeEL/>
    <aboutConfigEL/>
  </div>
</template>

<script>
import bannerEl from "@/views/page/about/banner";
import configZone1EL from "@/views/page/about/config_zone_1";
import configZone2EL from "@/views/page/about/config_zone_2";
import bannerZone2EL from "@/views/page/about/banner_zone_2";
import matchOrgEl from "@/views/page/about/match_org";
import memberOrgEl from "@/views/page/about/org_member";
import expertCommitteeEL from "@/views/page/about/expert_committee";
import executiveCommitteeEL from "@/views/page/about/executive_committee";
import aboutConfigEL from "@/views/page/about/about_config";

export default {
  name: "article_edit",
  components:{
    bannerEl,
    configZone1EL,
    configZone2EL,
    bannerZone2EL,
    matchOrgEl,
    memberOrgEl,
    expertCommitteeEL,
    executiveCommitteeEL,
    aboutConfigEL,
  },
  data() {
    return {

    }
  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content-box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
</style>
