<template>
  <div class="main-content">
    <div class="main-title">组委会名单</div>
    <div class="main-btn">
      <span class="btn-add" @click="add">添加</span>
    </div>
    <div class="from">
      <div class="row">
        <template v-for="(item,key) in memberArr">
        <div class="row-box" :key="key">
          <div class="title name">姓名</div>
          <input type="text" placeholder="请输入姓名" v-model.trim="item.name">
          <div class="title job">职务</div>
          <input type="text" placeholder="请输入职务" v-model.trim="item.job">
          <img class="del" @click="del(key)" src="@/assets/user_works_del_icon.png">
        </div>
        </template>
      </div>
      <div class="btn-box">
        <span class="btn-confirm" @click="edit">保存</span>
      </div>
    </div>
  </div>
</template>

<script>
import {editConfig,getConfigInfo} from "@/request/api";
import {Warning} from "@/plugins/warning";

export default {
  name: "about_org_member",
  components:{

  },
  data() {
    return {
      id:0,
      configObj:{},
      memberArr:[]
    }
  },
  computed: {

  },
  created() {
    this.getInfo();
  },
  mounted() {

  },
  methods: {
    edit(){
      let val = JSON.stringify(this.memberArr);

      const requestData = {
        "key":this.configObj.key,
        "value": val,
      };

      editConfig(requestData).then(()=>{
        Warning.success("操作成功");
      })
    },
    del(key){
      this.memberArr.splice(key,1)
    },
    add(){
      this.memberArr.push({"name":"","job":""})
    },
    getInfo(){
      const requestData = {"key":"org_member"};
      getConfigInfo(requestData).then((res)=>{
        this.configObj = res.data;
        this.memberArr = JSON.parse(res.data.value);
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content{
  width: 100%;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-bottom: 45px;
  .main-title{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-left: 30px;
    margin-top: 30px;
  }
  .main-btn{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-right: 30px;
    margin-top: 30px;
    .btn-add{
      display: block;
      width: 100px;
      line-height: 48px;
      font-size: 21px;
      font-weight: 300;
      text-align: center;
      cursor: pointer;
      color: #fff;
      border-radius: 25px;
      background-color: #ba0704;
    }
  }
  .from{
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    border-collapse:separate;
    border-spacing:0px 30px;
    position: relative;
    .row{
      width: 100%;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      .row-box{
        width: 33%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        float: left;
        position: relative;
        padding: 15px;
        border: 1px solid #DDD1B4;
        .title{
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #000000;
          margin-bottom: 15px;
        }
        .name{
          width: 100%;
        }
        .job{
          width: 100%;
        }
        .del{
          width: 18px;
          height: 23px;
          position: absolute;
          top: 25px;
          right: 35px;
          cursor: pointer;
        }
        & > input{
          height: 50px;
          width: 100%;
          border-radius: 25px 25px 25px 25px;
          opacity: 1;
          border: 2px solid #DDD1B4;
          padding-left: 15px;
          padding-right: 15px;
          margin-right: 30px;
        }
        & > textarea{
          height: 200px;
          width: 100%;
          border-radius: 25px 25px 25px 25px;
          opacity: 1;
          border: 2px solid #DDD1B4;
          padding: 15px;
        }
        .el-select{
          margin-right: 15px;
        }
        .address{
          margin-top: 15px;
          min-width: 600px;
        }
      }
    }
    .thumb-box{
      width: 300px;
      height: 200px;
      position: absolute;
      top:0;
      right: 30px;
      label {
        display: block;
        width: 100%;
        height: 100%;
        & > img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
          margin-top: 15px;
          border: 0.5px solid $blockColor--1;
        }
      }
    }
    .btn-box{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
      .btn-confirm{
        display: block;
        width: 100%;
        line-height: 48px;
        font-size: 21px;
        font-weight: 300;
        text-align: center;
        cursor: pointer;
        color: #fff;
        border-radius: 25px;
        background-color: #ba0704;
      }
    }
  }
}
</style>
