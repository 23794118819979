<template>
  <div class="main-content">
    <div class="main-title">大赛组织机构</div>
    <div class="from">
      <div class="row">
        <div class="title">主办单位</div>
        <input type="text" placeholder="请输入主办单位" v-model.trim="valueObj.zb_org">
      </div>
      <div class="row">
        <div class="title">承办单位</div>
        <input type="text" placeholder="请输入承办单位" v-model.trim="valueObj.cb_org">
      </div>
      <div class="row">
        <div class="title">协办单位</div>
        <input type="text" placeholder="请输入协办单位" v-model.trim="valueObj.xb_org">
      </div>
      <div class="row">
        <div class="title">承办单位</div>
        <input type="text" placeholder="请输入执行单位" v-model.trim="valueObj.zx_org">
      </div>
      <div class="row">
        <div class="title">内容</div>
        <textarea row="6" v-model.trim="valueObj.context"></textarea>
      </div>
      <div class="btn-box">
        <span class="btn-confirm" @click="edit">保存</span>
      </div>
    </div>
  </div>
</template>

<script>
import {editConfig,getConfigInfo} from "@/request/api";
import {Warning} from "@/plugins/warning";

export default {
  name: "page_about_match_org",
  components:{

  },
  data() {
    return {
      id:0,
      configObj:{},
      valueObj:{},
    }
  },
  computed: {

  },
  created() {
    this.getInfo();
  },
  mounted() {

  },
  methods: {
    edit(){
      let val = JSON.stringify(this.valueObj);

      const requestData = {
        "key":this.configObj.key,
        "value": val,
      };

      editConfig(requestData).then(()=>{
        Warning.success("操作成功");
      })
    },
    getInfo(){
      const requestData = {"key":"match_org"};
      getConfigInfo(requestData).then((res)=>{
        this.configObj = res.data;
        this.valueObj = JSON.parse(res.data.value);
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content{
  width: 100%;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-bottom: 45px;
  .main-title{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-left: 30px;
    margin-top: 30px;
  }
  .from{
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    border-collapse:separate;
    border-spacing:0px 30px;
    position: relative;
    .row{
      width: 100%;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      .title{
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #000000;
        margin-bottom: 15px;
      }
      & > input{
        height: 50px;
        min-width: 500px;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 2px solid #DDD1B4;
        padding-left: 15px;
        padding-right: 15px;
      }
      & > textarea{
        height: 200px;
        width: 100%;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 2px solid #DDD1B4;
        padding: 15px;
      }
      .el-select{
        margin-right: 15px;
      }
      .address{
        margin-top: 15px;
        min-width: 600px;
      }
    }
    .thumb-box{
      width: 300px;
      height: 200px;
      position: absolute;
      top:0;
      right: 30px;
      label {
        display: block;
        width: 100%;
        height: 100%;
        & > img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
          margin-top: 15px;
          border: 0.5px solid $blockColor--1;
        }
      }
    }
    .btn-box{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
      .btn-confirm{
        display: block;
        width: 100%;
        line-height: 48px;
        font-size: 21px;
        font-weight: 300;
        text-align: center;
        cursor: pointer;
        color: #fff;
        border-radius: 25px;
        background-color: #ba0704;
      }
    }
  }
}
</style>
